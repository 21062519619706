<template>
    <div class="app-main__inner">
        <div class="container mb-3 dm-sans-font">
            <!-- <div class="flex justify-between aligng-items-center">
                <div class="text-2xl text-gray-800 font-semibold">{{ $t('addWebshop') }} (WooCommerce)</div>
                <div class="flex justify-between space-x-5 align-items-center">
                    <router-link :to="{ name : 'addshopify'}" class="flex justify-center align-items-center font-semibold focus:bg-gray-800 text-base bg-gray-900 h-9 w-9 rounded shadow-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    </router-link>
                </div>
            </div> -->

            <div class="row mt-6 bg-white">
                <div class="col-md-4 mt-3">
                    <div class="form-group">
                        <label class="field-label">{{ $t('shopName') }}<span class="help-tooltip cursor-pointer" :title="$t('shopNameHelpText')"></span></label>
                        <input :disabled='queryName' v-model="webshopForm.shopName" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required>
                    </div>
                </div>
                <!-- <div class="col-md-4 mt-3">
                    <div class="form-group">
                        <label class="field-label">{{ $t('note') }}<span class="help-tooltip cursor-pointer" title="Tooltip Sample"></span></label>
                        <input v-model="webshopForm.note" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required>
                    </div>
                </div> -->
                <!-- <div class="col-md-4 mt-3">
                    <div class="form-group">
                        <label class="field-label">{{ $t('company') }}<span class="help-tooltip cursor-pointer" title="Tooltip Sample"></span></label>
                        <select v-model="webshopForm.companyId" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required>
                            <option :value="shop.id" v-for="shop in GET_CONNECTED_SHOP_LIST" :key="shop.id">{{ shop.name }}</option>
                        </select>
                    </div>
                </div> -->
                <!-- <div class="col-md-4">
                    <div class="form-group">
                        <label class="field-label">{{ $t('shopDomain') }}<span class="help-tooltip cursor-pointer" title="Tooltip Sample"></span></label>
                        <input v-model="webshopForm.shopURL" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                    </div>
                </div> -->
                <div class="col-md-4 mt-3">
                    <div class="form-group">
                        <label class="field-label">WooConsumer key<span class="help-tooltip cursor-pointer" :title="$t('woo_consumer_keyHelpText')"></span></label>
                        <div>
                            <input v-model="webshopForm.consumerKey" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="form-group">
                        <label class="field-label">WooConsumer secret<span class="help-tooltip cursor-pointer" :title="$t('woo_consumer_secretHelpText')"></span></label>
                        <div>
                            <input v-model="webshopForm.consumerSecret" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="field-label">{{ $t('approachingLowStockQty') }}<span class="help-tooltip cursor-pointer" :title="$t('approaching_low_stock_quantityHelpText')"></span></label>
                        <div>
                            <input v-model="webshopForm.approachingLowQty" type="number" min="0" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="field-label">{{ $t('criticalLowStockQty') }}<span class="help-tooltip cursor-pointer" :title="$t('critical_low_stock_quantityHelpText')"></span></label>
                        <div>
                            <input v-model="webshopForm.criticalLowQty" type="number" min="0" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="field-label">{{ $t('sufficientQty') }}<span class="help-tooltip cursor-pointer" :title="$t('sufficient_stock_quantityHelpText')"></span></label>
                        <div>
                            <input v-model="webshopForm.sufficientQty" type="number" min="0" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group flex align-items-center space-x-3">
                        <div class="flex justify-start">
                            <input v-model="webshopForm.syncStockWebshop" type="checkbox" class="checkbox-height">
                        </div>
                        <label class="field-label">{{ $t('syncStockFromSupplier') }}<span class="help-tooltip cursor-pointer" :title="$t('syncStockFromSupplierHelpText')"></span></label>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group flex align-items-center space-x-3">
                        <div class="flex justify-start">
                            <input v-model="webshopForm.automateOrder" type="checkbox" class="checkbox-height">
                        </div>
                        <label class="field-label">{{ $t('placeAutomaticOrders') }}<span class="help-tooltip cursor-pointer" :title="$t('placeAutomaticOrdersHelpText')"></span></label>
                    </div>
                </div>

                <div class="col-md-12 flex justify-center mb-5 mt-5">
                    <button @click="AddWooCommerce" :disabled='addingWebshop' class="flex justify-center align-items-center space-x-4 bg-green-500 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3 mb-5">
                        <span v-if="addingWebshop" class="flex align-items-center space-x-3">
                            <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            {{ $t('adding') }}
                        </span>
                        <span v-else>{{ $t('save') }}</span>
                    </button>
                </div>
            </div>

            <!-- <div class="flex justify-center md:mt-16">
                <button @click="AddWooCommerce" :disabled='addingWebshop' class="text-white rounded-full shadow-sm bg-green-400 font-black px-4 py-1">
                    <span v-if="addingWebshop" class="flex space-x-3">
                        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {{ $t('adding') }}
                    </span>
                    <span v-else>{{ $t('save') }}</span>
                </button>
            </div> -->
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    // import debounce from 'lodash.debounce'
    // import Paginate from 'vuejs-paginate'

    export default {
        name : 'AddWoocommerce',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
            // paginate : Paginate,
        },
        data () {
            return {
                webshopForm : {
                    shopName : '',
                    consumerKey : '',
                    consumerSecret : '',
                    note : '',
                    shopURL : '',
                    companyId : '',
                    approachingLowQty : '',
                    criticalLowQty : '',
                    sufficientQty : '',
                    syncStockWebshop : false,
                    automateOrder : false
                },
                addingWebshop : false,
                queryName : null
            }
        },
        computed : {
            ...mapGetters({
                GET_CONNECTED_SHOP_LIST : 'customer/GET_CONNECTED_SHOP_LIST',
                GET_WEBSHOP_PROVIDER : 'customer/GET_WEBSHOP_PROVIDER'
            })
        },
        mounted () {
            this.getProvider()
            this.getShopList()

            const { name } = this.$route.query

            if (name) {
                this.queryName = name
                // Pull webshop info for shopify
                this.getWooInfo()
            }
        },
        methods : {
            getShopList () {
            	let url = `?is_connected=True`
                this.$store.dispatch('customer/getConnectedShopList', { data : url})
            },
            getProvider () {
                this.$store.dispatch('customer/getIntegrationProvider')
                .then(_ => {})
                .catch(_ => {})
            },
            getWooInfo () {
                const data = window.localStorage.getItem('__woo__d')
                if (data) {
                    const payload = {
                        edit : true,
                        webshopId : data
                    }
                    this.$store.dispatch('customer/getConnectedWebshop', payload)
                    .then(res => {
                        this.webshopForm.shopName = res.data.shop_name
                        // this.webshopForm.companyId = res.data.company.id
                        this.webshopForm.automateOrder = res.data.place_automatic_orders
                        this.webshopForm.criticalLowQty = res.data.approaching_low_stock_quantity
                        this.webshopForm.syncStockWebshop = res.data.sync_stock_quantity_from_supplier_to_webshop
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                            window.Bus.$emit('sign-out')
                        }
                    })
                }
            },
            AddWooCommerce () {
                const webshop = this.queryName ? window.localStorage.getItem('__woo__d') : null

                if (this.webshopForm.shopName === '' || this.webshopForm.consumerKey === '' || this.webshopForm.consumerSecret === '' ||
                    this.webshopForm.approachingLowQty === '' || this.webshopForm.criticalLowQty === '' || this.webshopForm.syncStockWebshop === '' ||
                    this.webshopForm.automateOrder === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else {
                    this.addingWebshop = true
                    const filterProvider = this.GET_WEBSHOP_PROVIDER.filter(item => item.name === 'WooCommerce')
                    const payload = {
                        shop_url : this.webshopForm.shopURL,
                        approaching_low_stock_quantity : parseInt(this.webshopForm.approachingLowQty),
                        critical_low_stock_quantity : parseInt(this.webshopForm.criticalLowQty),
                        place_automatic_orders : this.webshopForm.automateOrder,
                        sync_stock_quantity_from_supplier_to_integration : this.webshopForm.syncStockWebshop,
                    }

                    if (this.queryName) {
                        // Process update
                        payload.webshop_id_save = webshop
                        this.$store.dispatch('customer/updateConnectedWebshop', payload)
                        .then(_ => {
                            this.addingWebshop = false
                            this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                            setTimeout(() => {
                                if (!this.queryName) {
                                    window.location.href = 'add-shopify'
                                }
                            }, 1000)
                        })
                        .catch(err => {
                            this.addingWebshop = false
                            this.$services.helpers.notification(err.response.data.error, 'error', this)
                            if (err.response.status === 401) {
                            this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                                window.Bus.$emit('sign-out')
                            }
                        })
                    } else {
                        // Process addition
                        payload.woo_consumer_key = this.webshopForm.consumerKey
                        payload.woo_consumer_secret = this.webshopForm.consumerSecret
                        payload.name = this.webshopForm.shopName
                        payload.provider = filterProvider[0].id

                        this.$store.dispatch('customer/addWooCommerce', payload)
                        .then(_ => {
                            this.addingWebshop = false
                            this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                            // this.clearField()
                        })
                        .catch(err => {
                            this.addingWebshop = false
                            this.$services.helpers.notification(err.response.data.error, 'error', this)
                            if (err.response.status === 401) {
                            this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                                window.Bus.$emit('sign-out')
                            }
                        })
                    }
                }
            },
            clearField () {
                Object.keys(this.webshopForm).forEach(element => {
                    if (typeof (this.webshopForm[element]) !== 'string') {
                        this.webshopForm[element] = false
                    } else {
                        this.webshopForm[element] = ''
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.c-border{
    border-width: 1px !important;
}
.form-control{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem .75rem !important;
}
.form-select{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem 1.5rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.btn-border-all{
    border: 1px solid $theme-secondary-color !important;
    color: $theme-secondary-color !important;
}
.table th, .table td {
    border: unset !important;
}
</style>
